import "core-js/stable";
import "regenerator-runtime/runtime";

import ready from "domready";
import "./application.css";
import setupCollabsible from "../collabsible";
import setupBookmarkletForm from "../setupBookmarkletForm";

ready(() => {
  setupCollabsible();
  setupBookmarkletForm();
});
